import type { IFormCallMasterModel } from '~/components/M/Forms/MFormCallMaster/MFormCallMaster.model';

export interface IFormRequestCourseMaterialsModel {
  name: string | number | Date | null;
  email: string | number | Date | null;
  captcha?: string | null;
  captcha_key?: string | null;
}

/**
 * @description Request form model
 */
export class FormRequestCourseMaterialsModel {
  public name: IFormRequestCourseMaterialsModel['name'];
  public email: IFormRequestCourseMaterialsModel['email'];
  public captcha: IFormCallMasterModel['captcha'];
  public captcha_key: IFormCallMasterModel['captcha_key'];

  constructor(
    attrs: Partial<IFormRequestCourseMaterialsModel> | null = {},
    enableCaptcha: boolean = false,
  ) {
    attrs = attrs ?? {};

    this.name = attrs.name || null;
    this.email = attrs.email || null;

    if (enableCaptcha) {
      this.captcha = attrs.captcha || null;
      this.captcha_key = attrs.captcha_key || null;
    }
  }

  public get captchaEnabled() {
    return this.captcha;
  }

  public set captchaEnabled(captcha: IFormCallMasterModel['captcha']) {
    this.captcha = captcha;
  }

  public reset() {
    this.name = null;
    this.email = null;
    this.captcha = this.captchaEnabled ? null : undefined;
    this.captcha_key = this.captchaEnabled ? null : undefined;
  }

  public toJSON(): IFormRequestCourseMaterialsModel {
    return {
      name: this.name,
      email: this.email,
      captcha: this.captchaEnabled ? this.captcha : undefined,
      captcha_key: this.captchaEnabled ? this.captcha_key : undefined,
    };
  }
}
